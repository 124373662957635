<template>
  <div class="container mt-3">
    <div class="d-flex flex-row justify-content-between align-items-end">
      <h3 class="pt-2">Регистрация юридического лица или ИП</h3>
      <div class="mb-2" v-if="modified && !state.saving">
        <button class="btn btn-outline-dark w-100" style="position: relative; padding-left: calc(24px + 0.75rem)"
                @click.prevent="save">
          <span class="material-icons fs-5" style="position: absolute; left: 12px; top: 6px;">save</span>
          Сохранить
        </button>
      </div>
      <div class="mb-2" style="position: relative;" v-else>
        <div class="spinner-border spinner-border-sm text-success" role="status" v-if="state.saving">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="material-icons text-success" style="position: absolute; left: -26px; top: -3px" v-else>done</span>
        {{ state.saving ? 'Сохранение' : 'Сохранено' }}
      </div>
    </div>

    <div class="card p-3">
      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-50 pe-3" label="ИНН" v-model:value="state.data.inn"
                 :error="v$.inn.$errors"
                 required/>
        <!--        <button class="btn btn-outline-dark w-100 mt-auto" :style="v$.inn.$error ? 'margin-bottom: 1.25em' : ''"-->
        <!--                @click.prevent="fillInn" :disabled="!state.data.inn">-->
        <!--          Заполнить по ИНН-->
        <!--        </button>-->
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Наименование юр. лица / ИП"
                 v-model:value="state.data.name"
                 :error="v$.name.$errors"
                 required/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="ОГРН/ОГРНИП" v-model:value="state.data.ogrn"/>
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Юр. адрес" v-model:value="state.data.address"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Факт. адрес" v-model:value="state.data.realAddress"/>
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Ген. директор" v-model:value="state.data.head"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Город" v-model:value="state.data.city"/>
      </div>
    </div>

    <div class="card p-3 mt-3">
      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-50 pe-3" label="БИК" v-model:value="state.data.bik"/>
        <!--        <button class="btn btn-outline-dark w-100 mt-auto" :style="v$.bik.$error ? 'margin-bottom: 1.25em' : ''"-->
        <!--                @click.prevent="fillInn" :disabled="!state.data.bik">-->
        <!--          Заполнить по БИК-->
        <!--        </button>-->
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Наименование банка" v-model:value="state.data.bank"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="КПП" v-model:value="state.data.kpp"/>
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Рассчётный счёт" v-model:value="state.data.account"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Корр. счёт" v-model:value="state.data.corrAccount"/>
      </div>
    </div>

    <div class="card p-3 mt-3">
      <h4 class="">Контакты</h4>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Номер телефона" v-model:value="state.data.phone"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Сайт" v-model:value="state.data.website"/>
      </div>

      <div class="flex-row d-flex w-100 align-items-end">
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Email" v-model:value="state.data.email"/>
        <p-input margin="mt-0" class="mt-1 w-100 pe-3" label="Статус организации"
                 v-model:value="kindMap[state.data.kind]"
                 disabled/>
      </div>
    </div>
  </div>
</template>

<script setup>

import api from '@/api'
import urls from '@/api/urls'
import { requiredRu } from '@/common'
import useVuelidate from '@vuelidate/core'
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const state = reactive({
  data: {
    inn: '',
    name: '',
    ogrn: '',
    address: '',
    realAddress: '',
    head: '',
    city: '',
    bik: '',
    bank: '',
    kpp: '',
    account: '',
    corrAccount: '',
    phone: '',
    website: '',
    email: '',
    kind: '',
  },
  saving: false,
  initialData: null,
})
const modified = computed(() => state.initialData !== null && JSON.stringify(state.data) !== JSON.stringify(state.initialData))
const kindMap = {
  lab: 'Поверочная лаборатория',
  manager: 'Управляющая компания',
}

const fillInn = () => {
}

const rules = {
  inn: { requiredRu },
  name: { requiredRu },
}
const v$ = useVuelidate(rules, state.data)

const save = async () => {
  const ok = await v$.value.$validate()
  if (!ok) {
    return
  }
  state.saving = true
  state.modified = false
  try {
    const company = await api.post(urls.COMPANY.SAVE, state.data)
    store.commit('setCompany', company)
    for (const key in company) {
      state.data[key] = company[key]
    }
    state.initialData = Object.assign({}, state.data)
  } catch (err) {
    state.modified = true
  } finally {
    state.saving = false
  }
}

const updateCompany = () => {
  const company = store.state.company

  if (!company) {
    return
  }

  for (const key in company) {
    state.data[key] = company[key]
  }
  state.initialData = Object.assign({}, state.data)
  state.data.kind = company ? company.kind : store.state.user.kind
}

onMounted(updateCompany)
watch(() => store.state.company, updateCompany)
</script>

<style scoped>

</style>
